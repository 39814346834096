require("./index.scss");
require('bootstrap');
require('bootstrap/dist/css/bootstrap.css');
require("babel-core/register");
require("babel-polyfill");

const body = document.querySelector("body");
const offset = 150;
let previousScrollPosition = 0;
const breakpoint = 992;
const menuIcon = document.querySelector(".main-nav--icon");
const overlay = document.querySelector("div.menu-overlay");
const nav = document.querySelector(".main-nav");
const sections = nav.querySelector("div.main-nav--center");
const html = document.querySelector("html");

const winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;


menuIcon.addEventListener("click", () => {

    const visible = !nav.classList.contains("is-usernav-visible");

    toggleMenuNav(visible);
    toggleOverlay(visible);
    scrollLock(visible);

})

overlay.addEventListener("click", () => {

    toggleMenuNav(false);
    toggleOverlay(false);
    scrollLock(false);
})


window.addEventListener("scroll", () =>  { 

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if( scrollTop < offset ) { 

        window.scroll.state = "top";
        body.classList.add("nav-state--top");
        body.classList.remove("nav-state--down");
        body.classList.remove("nav-state--up");


    } else if (scrollTop >= previousScrollPosition && scrollTop >= offset) { 

        window.scroll.state = "down";
        body.classList.add("nav-state--down");
        body.classList.remove("nav-state--top");
        body.classList.remove("nav-state--up");

    } else { 

        window.scroll.state = "up";
        body.classList.add("nav-state--up");
        body.classList.remove("nav-state--down");
        body.classList.remove("nav-state--top");

    }

    previousScrollPosition = scrollTop;
    
});

const toggleMenuNav = (visible) => {

    if(winWidth < breakpoint){

        if(visible) { 
    
            nav.classList.add("is-usernav-visible");
            sections.classList.add("is-visible");
    
        } else { 
    
            nav.classList.remove('is-usernav-visible');
            sections.classList.remove("is-visible");
        }
    
    }
}

const toggleOverlay = (state) => {

    return state ? overlay.classList.add("menu-overlay--show") : overlay.classList.remove("menu-overlay--show");

};

const scrollLock = (state) => {

    return state ? html.classList.add("is-scroll-locked") : html.classList.remove('is-scroll-locked');

}